import { getUserInfo, getToken } from '@gaodun.com/rainbow';
import { getCookie, setCookie } from '../utils/cookie';
import { getEnvStr } from '../utils';
import { ACCESS_TOKEN, VISITOR_TOKEN, USER_INFO, WX_USER_DATA, REFRESH_TOKEN, TOKEN_SOURCE, USER_DATA } from '../utils/keys';
import LStorage from '../utils/LStorage';
import { jsbridgeRun } from '../utils/jsbridge';

export type UserLoginInfo = {
    accessToken: string;
    visitToken: string;
    userInfo: any;
    wxUserInfo: any;
    needPhoneBind: boolean;
};

/**
 * 检查微信授权
 * @param visitor 是否允许游客访问
 * @returns token
 */
export const getUserLoginInfo = (): Promise<UserLoginInfo> =>
    new Promise((resolve, reject) => {
        let opts = {
            new: () => {
                const visitToken = getCookie(getEnvStr(VISITOR_TOKEN));
                Promise.all([getToken(), getUserInfo()])
                    .then(res => {
                        const [appToken, appUserinfo] = res;
                        const localToken: any = (appToken?.accessToken || '').replace('Basic ', '');
                        // 重写app用户信息对应h5参数名
                        const { nickName, sessionID, studentID, uid, userImage, userPhone } = appUserinfo;
                        const userInfo: any = {
                            Phone: userPhone,
                            NickName: nickName,
                            PictureUrl: userImage,
                            SessionID: sessionID,
                            StudentId: studentID,
                            Uid: uid,
                        };
                        const wxUserInfo = {};
                        const needPhoneBind: boolean = !localToken || !userInfo || !userInfo.Phone;

                        resolve({
                            accessToken: localToken,
                            visitToken: visitToken,
                            userInfo: userInfo || {},
                            wxUserInfo: wxUserInfo || {},
                            needPhoneBind: needPhoneBind,
                        });
                    })
                    .catch(e => {
                        resolve({
                            accessToken: '',
                            visitToken: visitToken,
                            userInfo: {},
                            wxUserInfo: {},
                            needPhoneBind: true,
                        });
                    });
            },
            web: () => {
                const localToken = getCookie(ACCESS_TOKEN);
                const visitToken = getCookie(getEnvStr(VISITOR_TOKEN));
                const userData =  getCookie(USER_DATA);
                const hasUserData = userData && !!Object.keys(userData)?.length;
                const userInfo = hasUserData? userData :(LStorage.get(USER_INFO) || {});
                const wxUserInfo = getCookie(getEnvStr(WX_USER_DATA));
                // 没有登录 || 没有用户信息 || 没有手机号
                const needPhoneBind: boolean = !localToken || !userInfo || !userInfo.Phone;

                resolve({
                    accessToken: localToken,
                    visitToken: visitToken,
                    // 需要兼容跨域名同步token时，userInfo不重新请求的问题
                    userInfo,
                    wxUserInfo: wxUserInfo || {},
                    needPhoneBind: needPhoneBind,
                });
            },
        };
        let source = sessionStorage.getItem(TOKEN_SOURCE);
        if (source == 'cookie') {
            opts.web();
        } else {
            jsbridgeRun(opts);
        }
    });

/**
 * 保存用户登录后的信息
 * @param data 登录返回信息
 */
export const setUserLoginInfo = (data: any) => {
    const { accessToken, refreshToken, result } = data;
    // 保存token及用户信息
    setCookie(ACCESS_TOKEN, accessToken);
    setCookie(REFRESH_TOKEN, refreshToken);
    LStorage.set(USER_INFO, result.UserData);

    // app 环境下使用gdclass的登录，标识token使用从cookie中获取
    jsbridgeRun({
        new: () => {
            sessionStorage.setItem(TOKEN_SOURCE, 'cookie');
        },
    });
};
