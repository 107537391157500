import * as Sentry from "@sentry/react";
import { getEnv } from './index';

export const SentryInit = (open = false) => {
    console.log("🚀 ~ SentryInit ~ open:", open)
    // 只在正式环境上报
    if (!getEnv() || open) {
        Sentry.init({
            dsn: "https://dd7244b151e9a01b274483ae7cc1f251@sentry.gaodun.com/50",
            environment: process.env.CROSS_ENV, // 上报环境变量   test|pre|production
            integrations: [
                Sentry.browserTracingIntegration(), // 浏览器性能追踪
                Sentry.replayIntegration(), // 捕获用户行为，并以类似视频播放的方式再现
                // Sentry.browserProfilingIntegration(), // 性能分析
            ],
            tracesSampleRate: 1.0, // 触发异常后发送给 Sentry 的概率
            maxBreadcrumbs: 50, // 控制应捕获的面包屑(行为栈)的总量
            profilesSampleRate: 0.01, // 性能分析的采样率
            // Session Replay
            replaysSessionSampleRate: 0, // 重播的采样率
            replaysOnErrorSampleRate: 0, // 发生错误时录制的重放的采样率，最多从错误放生时前1分钟开始记录
            // 忽略所有与网络相关的错误
            ignoreErrors: [
                "Network Error",
                "Network request failed",
                "未登录",
                "已授权，未绑定账号"
            ],
        });
    }
}